import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { EquippedInventory, Inventory } from 'shared';

import { updateInventory } from '../services/inventory';

import { useOAuthToken } from './AuthTokenProvider';
import { useLoading } from './useLoading';

type State = {
  inventory: Inventory | undefined;
  updateSelected: (value: Partial<EquippedInventory>) => void;
  setInventory: (value: Inventory) => void;
};

const Context = createContext<State | null>(null);

export function useInventory() {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('useInventory must be used within Inventory');
  }
  return ctx;
}

export function InventoryProvider({ children }: PropsWithChildren<unknown>) {
  const [inventory, setInventory] = useState<Inventory | undefined>(undefined);
  const { setLoading } = useLoading();
  const { token } = useOAuthToken();

  const updateSelected = useCallback(
    async (value: Partial<EquippedInventory>) => {
      if (!inventory || !token) {
        return;
      }
      try {
        const equipped = { ...inventory.equipped, ...value };
        setLoading(true);
        await updateInventory(token, equipped);
        setInventory({ ...inventory, equipped });
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [inventory, setLoading, token]
  );

  const state = useMemo(() => ({ inventory, updateSelected, setInventory }), [inventory, updateSelected]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
