import { useEffect, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import "./Login.css";

import { TitleLogo } from '../components/title';
import logo from '../assets/images/logo.png';

import { useOAuthState } from './hooks/useOAuthState';

type Props = {
  loginError?: string | undefined;
}

export function Login({ loginError }: Props) {
  const [state, setState] = useOAuthState();

  useEffect(() => {
    setState(uuidv4());
  }, [setState])

  const url = useMemo(() =>
    `https://id.twitch.tv/oauth2/authorize
?client_id=${process.env.REACT_APP_CLIENT_ID || ''}
&redirect_uri=${process.env.REACT_APP_CLIENT_URL || ''}
&response_type=token
&state=${state || ''}`,
    [state]);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center" style={{ width: "100vh", height: "100vh" }}>
      <div className="d-flex justify-content-center">
        <TitleLogo lg logo={logo} />
      </div>
      <Button href={url} variant='purple' className='fs-5'>
        <svg overflow="visible" width="40" height="40">
          <polygon points="13 8 8 13 8 31 14 31 14 36 19 31 23 31 32 22 32 8" className="color-white" x={0} y={0} />
          <polygon points="26 25 30 21 30 10 14 10 14 25 18 25 18 29 22 25" className="color-purple" />
          <path d="M20,14 L22,14 L22,20 L20,20 L20,14 Z M27,14 L27,20 L25,20 L25,14 L27,14 Z" className="color-white" />
        </svg>
        Se connecter avec twitch
      </Button>
      {!!loginError && <span>{loginError}</span>}
    </Container>
  );
}