import { useMemo } from 'react';
import { Image, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import { useInventory } from '../hooks/useInventory';
import titleColor from '../assets/images/categories/TitleColor.png';
import titleFace from '../assets/images/categories/TitleFace.png';
import titleBody from '../assets/images/categories/TitleBody.png';
import titleHead from '../assets/images/categories/TitleHead.png';
import titleWeapon from '../assets/images/categories/TitleWeapon.png';

import { ButtonsTable } from './buttons-table/ButtonsTable';

import './styles/tabButtons.css';
import './styles/tabContent.css';
import './styles/scrollbar.css';

enum TabType {
  COLOR = 'color',
  FACE = 'face',
  BODY = 'body',
  HEAD = 'head',
  WEAPON = 'weapon',
}

const tabTitles: {
  [v in TabType]: string;
} = {
  [TabType.COLOR]: titleColor,
  [TabType.FACE]: titleFace,
  [TabType.BODY]: titleBody,
  [TabType.HEAD]: titleHead,
  [TabType.WEAPON]: titleWeapon,
};

class TabItem {
  constructor(public type: TabType, public items: number[], public selected: number, public updateSelected: (v: number) => void) {}
}

function TabTitle({ type }: { type: TabType }) {
  return <Image src={tabTitles[type]} alt="" width="100%" height="100%" />;
}

export function PickersTabs() {
  const { inventory, updateSelected } = useInventory();

  const items = useMemo(() => {
    if (!inventory) {
      return undefined;
    }
    return [
      new TabItem(TabType.COLOR, inventory.available.color, inventory.equipped.color, (v: number) => updateSelected({ color: v })),
      new TabItem(TabType.FACE, inventory.available.face, inventory.equipped.face, (v: number) => updateSelected({ face: v })),
      new TabItem(TabType.BODY, inventory.available.body, inventory.equipped.body, (v: number) => updateSelected({ body: v })),
      new TabItem(TabType.HEAD, inventory.available.head, inventory.equipped.head, (v: number) => updateSelected({ head: v })),
      new TabItem(TabType.WEAPON, inventory.available.weapon, inventory.equipped.weapon, (v: number) => updateSelected({ weapon: v })),
    ];
  }, [inventory, updateSelected]);

  if (!items) {
    return null;
  }

  return (
    <Tabs id="tabs" variant="pills" defaultActiveKey="color" mountOnEnter unmountOnExit className="custom-tabs nav-justified pb-3 justify-content-center">
      {items.map((i) => (
        <Tab key={i.type} eventKey={i.type} className="custom-tab-content" tabClassName="custom-tab-button p-0" title={<TabTitle type={i.type} />}>
          <SimpleBar className="custom-scrollbar">
            <ButtonsTable basePath={i.type} items={i.items} selected={i.selected} updateSelected={i.updateSelected} />
          </SimpleBar>
        </Tab>
      ))}
    </Tabs>
  );
}
