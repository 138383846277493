import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type State = {
  loading: boolean;
  setLoading: (value: boolean) => void;
};

const Context = createContext<State | null>(null);

export function useLoading() {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return ctx;
}

export function LoadingProvider({ children }: PropsWithChildren<unknown>) {
  const [loading, setLoading] = useState(false);

  const state = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
