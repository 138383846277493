import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

import { logout as apiLogout } from '../services/logout';

import { useLocalStorage } from './useLocalStorage';

type State = {
  token?: string;
  setToken: (v?: string) => void;
  logout: () => Promise<void>
};

const Context = createContext<State | null>(null);

export function useOAuthToken() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error('Should be nested under a OAuthTokenProvider component');
  }

  return context;
}

export function OAuthTokenProvider({ children }: PropsWithChildren<unknown>) {
  const [token, setToken] = useLocalStorage<string | undefined>('token', process.env.REACT_APP_DEBUG ? '1' : undefined);

  const logout = useCallback(async () => {
    try {
      if (token) {
        await apiLogout(token);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.error('invalid token')
    }finally {
      setToken(undefined);
    }

  }, [setToken, token]);

  const state: State = useMemo(() => ({
    token, setToken, logout
  }), [logout, setToken, token]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
