import clsx from 'clsx';
import { Image } from 'react-bootstrap';

import './Title.css';

const defaultLogo = 'https://static-cdn.jtvnw.net/jtv_user_pictures/ece30d3a-5e81-48e2-b3f7-3b3a303c4e70-profile_image-300x300.png';

interface Props {
  lg?: boolean;
  logo?: string;
}

export function TitleLogo({ lg = false, logo = defaultLogo }: Props) {
  return <Image src={logo} alt="DFG Logo" className={clsx(lg ? 'logo-lg logo-shadow-lg' : 'logo logo-shadow')} />;
}
