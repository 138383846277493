import { ToggleButton, Image } from 'react-bootstrap';

import background from '../../assets/images/items/Item.png';
import checkMark from '../../assets/images/items/GreenTick.png';

import './styles/Button.css';
import './styles/Icon.css';

type Props = {
  basePath: string;
  items: number[];
  selected: number;
  updateSelected: (v: number) => void;
};

export function ButtonsTable({ basePath, items, selected, updateSelected }: Props) {
  return (
    <>
      {items.map((i) => (
        <ToggleButton
          id={`${basePath}-${i}`}
          key={i}
          value={i}
          checked={selected === i}
          variant="transparent"
          type="radio"
          onChange={(e) => updateSelected(parseInt(e.currentTarget.value, 10))}
        >
          <div style={{ position: 'relative' }}>
            <Image src={background} alt="" fluid />
            <Image src={`/assets/images/items/${basePath}/${i}.png`} alt="" className="button-content" />
            {selected === i && <Image src={checkMark} alt="" className="button-icon" />}
          </div>
        </ToggleButton>
      ))}
    </>
  );
}
