import { Stack } from 'react-bootstrap';

import { Outfit } from '../outfit';
import { PickersTabs } from '../pickers-tabs';

export function TabsPanel() {
  return (
    <Stack direction="horizontal" gap={5} className="pb-3 h-100">
      <Stack gap={2} className="mh-100 mw-65">
        <PickersTabs />
      </Stack>
      <Outfit />
    </Stack>
  );
}
