import { Button, Stack } from 'react-bootstrap';

import { Title, TitleLogo } from '../components/title';
import { useOAuthToken } from '../hooks/AuthTokenProvider';

import './Header.css';

export function Header() {
  const { logout } = useOAuthToken();

  return (
    <Stack direction='horizontal'>
      <TitleLogo />
      <Title />
      <Button onClick={() => logout()} className='ms-auto logout-button'>
        Déconnexion
      </Button>
    </Stack>
  );
}