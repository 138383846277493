import { PropsWithChildren } from 'react';

import { OAuthTokenProvider } from './hooks/AuthTokenProvider';
import { InventoryProvider } from './hooks/useInventory';
import { LoadingProvider } from './hooks/useLoading';

export function Providers({children}: PropsWithChildren<unknown>) {
  return (
    <LoadingProvider>
      <OAuthTokenProvider>
        <InventoryProvider>
          {children}
        </InventoryProvider>
      </OAuthTokenProvider>
    </LoadingProvider>
  )
}