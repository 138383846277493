import axios from 'axios';
import { EquippedInventory, EquippedInventoryPayload, InventoryPayload } from 'shared';

const url = '/api/inventory';

export async function getInventory(token: string) {
  const { data } = await axios.get<InventoryPayload>(url, { headers: { Authorization: token } });
  return data;
}

export async function updateInventory(token: string, inventory: EquippedInventory) {
  return axios.put<EquippedInventoryPayload>(url, inventory, { headers: { Authorization: token } });
}
