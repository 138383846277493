import { AxiosError } from 'axios';
import { parse } from 'query-string';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';

import { TabsPanel } from './components/TabsPanel';
import { Header } from './header';
import { useOAuthToken } from './hooks/AuthTokenProvider';
import { useInventory } from './hooks/useInventory';
import { Login } from './login';
import { useOAuthState } from './login/hooks/useOAuthState';
import { getInventory } from './services/inventory';
import { useLoading } from './hooks/useLoading';
import './loading.css';


type AuthValidation = {
  access_token?: string;
  scope?: string;
  state?: string;
  token_type?: string;
};

export function App() {
  const [state] = useOAuthState();
  const { token, setToken } = useOAuthToken();
  const [loginError, setLoginError] = useState<string | undefined>();
  const { setInventory } = useInventory();
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          setLoading(true);
          const inventory = await getInventory(token);
          setInventory(inventory);
          setLoading(false);
        }
      } catch (error) {
        const err = error as AxiosError;
        // eslint-disable-next-line no-console
        console.error(err);
        setToken();
        setLoginError(err.message);
      }
    })();
  }, [setInventory, setLoading, setToken, token]);

  useLayoutEffect(() => {
    const { hash } = window.location;
    window.location.hash = '';
    const params: AuthValidation = parse(hash);
    if (params.state) {
      if (params.state === state && params.access_token) {
        setToken(`${params.access_token}`);
        setLoginError(undefined);
      } else {
        setLoginError('Unable to connect');
      }
    }
  }, [setToken, state]);

  if (!token) {
    return <Login loginError={loginError} />;
  }

  return (
    <>
      <div className={loading ? 'loader-container' : ''} />
      <Container className="py-5 vh-100 d-flex">
        <Stack gap={5} className="h-100 aaa">
          <Header />
          <TabsPanel />
        </Stack>
      </Container>
    </>
  );
}
