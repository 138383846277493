import shadow from '../assets/images/CharaShadow.png'
import { useInventory } from '../hooks/useInventory';

import './Outfit.css';

export function Outfit() {
  const { inventory } = useInventory();

  if (!inventory) {
    return null;
  }

  return (
    <div>
      <img className="absolute" src={shadow} alt="Shadow" />
      <img className="absolute" src={`/assets/images/skins/weapon/${inventory.equipped.weapon}.png`} alt="Weapon" />
      <img className="absolute" src={`/assets/images/skins/color/${inventory.equipped.color}.png`} alt="Color" />
      <img className="absolute" src={`/assets/images/skins/face/${inventory.equipped.face}.png`} alt="Face" />
      <img className="absolute" src={`/assets/images/skins/head/${inventory.equipped.head}.png`} alt="Head" />
      <img className="absolute" src={`/assets/images/skins/body/${inventory.equipped.body}.png`} alt="Body" />
    </div>
  );
}